<template>
  <div class="detail-ticket">
    <div class="title">
      <div class="text">Ticket #{{ ID }}</div>
      <div class="button" @click="close()">
        <b-button variant="outline-success">
          <i class="fad fa-times-circle"></i>
        </b-button>
      </div>
    </div>
    <div class="content-ticket" ref="box-chat">
      <div
        class="ticket"
        :class="{ support: UserInfo.id !== chat.ticket_User }"
        v-for="chat in Detail"
        :key="`${chat.ticket_Content}-${chat.ticket_ID}4`"
      >
        <div class="avatar"></div>
        <div class="detail">
          <div
            class="d-flex align-items-center"
            :class="
              UserInfo.id !== chat.ticket_User ? 'justify-content-between' : 'justify-content-end'
            "
          >
            <div class="name">
              {{ getName(chat.ticket_User) }}
            </div>
            <div class="date">{{ chat.ticket_Time }}</div>
          </div>
          <div class="description">
            {{ chat.ticket_Content }}
          </div>
        </div>
      </div>
    </div>
    <div class="footer-ticket">
      <b-input-group>
        <b-form-input v-model.trim="subject.content" placeholder="Enter You Message"></b-form-input>
        <b-input-group-append>
          <b-button variant="info" @click="onSend"> <i class="fad fa-paper-plane"></i> </b-button>
        </b-input-group-append>
      </b-input-group>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    ID: { type: Number, default: null, required: true },
    Subject: { type: Number, default: null, required: true },
  },
  data() {
    return {
      subject: {
        content: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      Detail: 'support/Detail',
      UserInfo: 'user/UserInfo',
    }),
  },
  watch: {
    Detail: {
      handler() {
        this.toBottom();
      },
      deep: true,
    },
  },
  methods: {
    getName(id) {
      const User = this.UserInfo;
      if (User.id === id) {
        if (User.name) {
          const name = `${User.surname} ${User.name}`;
          return name;
        }
        const name = User.email.split('@')[0];
        return name;
      }
      return 'Support';
    },
    toBottom() {
      const elm = this.$refs['box-chat'];
      elm.scrollTop = elm.scrollHeight;
    },
    async onSend() {
      const token = await this.genarateCaptChaV3('create_account');
      if (!token) {
        this.$toastr.e('Too Fast! Please Wating Recaptcha.', 'Withdraw Failed');
      }
      this.subject.token = token;
      this.subject.subject = this.Subject;
      this.subject.replyID = this.ID;
      this.$store.dispatch('support/req_postSubject', this.subject);
    },
    close() {
      this.$emit('close-detail', true);
    },
  },
  mounted() {
    this.toBottom();
    this.$store.dispatch('support/req_getTicketDetail', this.ID);
  },
  created() {
    this.$store.dispatch('support/req_SetUpTick');
    this.unsubscribe = this.$store.subscribe((mutation) => {
      switch (mutation.type) {
        case 'support/POST_SUBJECT_SUCCESS':
          this.$store.dispatch('support/req_getTicketDetail', this.ID);
          this.subject.content = '';
          this.outLoad();
          break;
        default:
          break;
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
};
</script>

<style lang="scss">
.detail-ticket {
  width: 100%;
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 15px;
    box-shadow: 0px 3px 5px -2px #00000075;
    .text {
      font-weight: 600;
      font-size: 20px;
      color: #2f394e;
      text-align: left;
      padding: 0 0 5px;
      text-transform: uppercase;
      width: 100%;
    }
    button {
      background: #FF5C00;
      padding: 0;
      border-radius: 50%;
      width: 35px;
      height: 35px;
      color: #cccc;
      display: flex;
      align-items: center;
      justify-content: center;
      i,
      svg {
        width: 100%;
        color: #fff;
        font-size: 25px;
      }
    }
  }
  .content-ticket {
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 15px 5px;
    .ticket {
      border-radius: 10px;
      text-align: right;
      width: 70%;
      margin: 5px 5px 5px auto;
      padding: 5px;
      box-shadow: 0px 0px 5px 2px #00000020;
      background: #9f9add36;
      min-width: 280px;
      .date {
        color: #6c757d;
        margin-left: 5px;
        font-weight: 600;
        font-size: 10px !important;
        text-transform: capitalize;
      }
      .description {
        font-size: 16px;
        font-weight: 600;
        padding: 5px;
        text-align: left;
      }
      .name {
        display: none;
      }
      &.support {
        margin: 5px auto 5px 5px;
        text-align: left;
        background-color: #fff;
        .name {
          color: #6c757d;
          margin-left: 5px;
          font-weight: 600;
          font-size: clamp(15px, 2vw, 17px);
          text-transform: capitalize;
          display: block;
        }
      }
    }
  }
  .footer-ticket {
    box-shadow: 0px -3px 5px -2px #00000035;
    width: 100%;
    padding: 5px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}
</style>
